let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Prashidha Shrestha",
    "role": "Data Scientist",
    "linkedinId":"Your LinkedIn Id",
    "skypeid": "Your skypeid",
    "roleDescription": "I like dabbling in various parts of AI development and like to learn about new technologies, write technical articles or simply play games in my free time.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/prashidha-shrestha-b8627a131/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/psth2876",
          "className":"fa fa-github"
        },
        {
          "name":"skype",
          "url":"http://twitter.com",
          "className":"fa fa-twitter"
        }
      ],
    "aboutme":"Welcome to my personal portfolio website! I'm thrilled to have you here.I am passionate about harnessing the power of data and the AWS Cloud to drive innovation and create impactful solutions. With a deep understanding of data analysis and cloud computing, I strive to leverage cutting-edge technologies to solve complex problems and optimize processes. Thank you for visiting my portfolio, and I invite you to explore my projects and learn more about my journey in data and cloud computing. Let's connect and collaborate to turn ideas into reality and drive meaningful change together.",
    "address":" Gatthaghar, Madhyapur Thimi",
    "website":"http://www.prashidha76.com.np",
    "education":[
      {
        "UniversityName":"Islington College (London Metropolitan University)",
        "specialization":"MSc IT & Applied Security",
        "MonthOfJoining":"March",
        "YearOfJoining":"2021",
        "MonthOfLeaving":"March",
        "YearOfLeaving":"2023",
        "Achievements":"Graduated"
      },
      {
        "UniversityName":"Sharda University",
        "specialization":"B.Tech Informtion Technology",
        "MonthOfJoining":"Aug",
        "YearOfJoining":"2016",
        "MonthOfLeaving":"July",
        "YearOfLeaving":"2020",
        "Achievements":"Grduated"
      }
    ],
    "work":[
      {
        "CompanyName":"Invisible Technologies inc",
        "specialization":"AI Data Trainer",
        "MonthOfJoining":"Aug",
        "YearOfJoining":"2022",
        "MonthOfLeaving":"July",
        "YearOfLeaving":"2023",
        "Achievements":"Evaluate and label metadata, measure language model outputs, revise and edit model outputs, and compare labeled data against client measurements, all based on user instructions, language model capabilities, and client parameters."
      },
      {
        "CompanyName":"Last Door Solution Pvt Ltd",
        "specialization":"Data Analyst",
        "MonthOfJoining":"Feb",
        "YearOfJoining":"2022",
        "MonthOfLeaving":"July",
        "YearOfLeaving":"2022",
        "Achievements":"Data Analysis and Customer Segmentation for DWL"
      }
    ],
    "skillsDescription":"Your skills here",
    "skills":[
      {
        "skillname":"Cloud: AWS"
      },
      {
        "skillname":"Data & Statistical Analysis: Python & R"
      },
      {
        "skillname":"Tools: PowerBI, Excel"
      },
      {
        "skillname":"Databases: MySQL, PostgreSQL"
      }
    ],
    "portfolio":[
      {
        "name":"project1",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      },
      {
        "name":"project2",
        "description":"mobileapp",
        "imgurl":"images/portfolio/project.jpg"
      },
      {
        "name":"project3",
        "description":"mobileapp",  
        "imgurl":"images/portfolio/project2.png"
      },
      {
        "name":"project4",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      }
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData